<template>
  <div class="page">
    <div class="s1">
      <draggable
        v-model="contentMsgs"
        handle=".drag-item"
        animation="300"
        :force-fallback="true"
      >
        <transition-group>
          <div v-for="(item,index) in contentMsgs" :key="index" class="item flex p-card">
            <div class="drag-item pointer"><div class="icon-1" /></div>
            <div class="flex-1">
              <msg-box :data="item" />
            </div>
            <div class="divider-v ml-1" />
            <div class="ml-1" title="编辑" @click="editClick(item,index)"><div class="p-icon-edit" /></div>
            <div class="ml-1" title="删除" @click="contentMsgs.splice(index,1)"><div class="p-icon-delete" /></div>
          </div>
        </transition-group>
      </draggable>
      <template v-if="contentMsgs.length<5">
        <div class="p-card add" @click="dialog1=true;$store.commit('resetGlobalData')">添加</div>
        <div class="text-center color-gray mt-2">（还可添加{{ 5-contentMsgs.length }}条欢迎语）</div>
      </template>
    </div>

    <div class="x-padding-bottom" />
    <div class="p-footer">
      <div class="flex h-between flex-1">
        <label class="flex">
          <input v-model="randomSend" type="checkbox" class="radio mr-1">
          <div>随机发送一条</div>
        </label>
        <div class="flex">
          <button class="btn auto gray" @click="submit(0)">关闭</button>
          <button class="btn auto fill ml-3" @click="submit(1)">保存</button>
        </div>
      </div>
    </div>

    <select-source-type :data="contentMsgs" :show="dialog1" @update="contentMsgs = $event" @close="dialog1=false" />

  </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import msgBox from '@/components/msg-box/index.vue'
import selectSourceType from '@/components/select-source-type/index.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: { msgBox, selectSourceType, draggable: VueDraggableNext },
  data() {
    return {
      randomSend: true,
      dialog1: false,
      contentMsgs: []
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  watch: {
    '$route'(to, from) {
      if (from.path === '/welcomeWords') {
        this.getData()
      }
      if (to.path === '/welcomeWords') {
        this.$store.commit('resetGlobalData')
      }
    }
  },
  created() {
    /* const pick = this.globalData
    if (pick.contentMsgs) {
      this.contentMsgs = pick.contentMsgs
    }*/
    this.getData()
  },
  methods: {
    getData() {
      this.$get('/JoinChatroomGreet/GetJoinChatroomGreetContents').then(res => {
        this.contentMsgs = res.contentList
        this.randomSend = res.isRandomSendOne === 1
      })
    },
    editClick(item, index) {
      this.dialog1 = true
      this.$store.commit('setGlobalData', {
        ...item,
        isEdit: true,
        editIndex: index
      })
    },
    submit(n) {
      if (n) {
        this.$post('/JoinChatroomGreet/SaveJoinChatroomGreetContents', {
          isRandomSendOne: this.randomSend ? 1 : 0,
          contentList: this.contentMsgs
        }).then(res => {
          this.$Toast('保存成功')
          this.$router.go(-1)
        })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
